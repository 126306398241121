import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { Grid, TextField, Button, Autocomplete } from "@mui/material";
import { Title } from "../../../common/components/Title";
import { Notification } from "../../../common/components/Notification";
import { Divider } from "./StyledComponents";

import { agendamentosRequest } from "../../../redux/actions/sistema/agendamentosActions";
import { pacientesRequest } from "../../../redux/actions/sistema/pacientesActions";
import { addAgendamento, updAgendamento } from "../../../services/api";
import { decryptUser } from "../../../services/auth";

Modal.setAppElement("#root");

const toBrasiliaTime = (date) => {
  return new Date(date).toLocaleString("sv-SE", {
    timeZone: "America/Sao_Paulo",
  });
};

export const FormAgendamento = ({ isOpen, onRequestClose, event }) => {
  const dispatch = useDispatch();
  const user = decryptUser();
  const pacientes = useSelector((state) => state.pacientesReducer.pacientes);
  const [paciente, setPaciente] = useState(null);
  const [nome_temporario, setNomeTemporario] = useState("");
  const [telefone, setTelefone] = useState("");
  const [dataHora, setDataHora] = useState("");
  const [status, setStatus] = useState(1);
  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  useEffect(() => {
    dispatch(pacientesRequest());
    if (event) {
      const localDateTime = new Date(
        event.start.getTime() - event.start.getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 16);
      setDataHora(localDateTime);
      setPaciente(
        pacientes.find((pac) => pac.id === event.paciente_id) || null
      );
      setNomeTemporario(event.nome_temporario);
      setTelefone(event.telefone);
      setStatus(event.status);
    } else {
      setPaciente(null);
      setNomeTemporario("");
      setDataHora("");
      setStatus(1);
    }
  }, [dispatch, event]);

  useEffect(() => {
    if (!paciente) {
      setPaciente(null);
      setTelefone("");
    }
  }, [paciente]);

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  const handlePacienteChange = (event, value) => {
    setPaciente(value);
    if (value) {
      setTelefone(value.telefone);
    } else {
      setPaciente(null);
      setTelefone("");
    }
  };

  const removeMask = (value) => {
    return value.replace(/\D/g, "");
  };

  async function handleSubmit() {
    if ((paciente || nome_temporario) && dataHora) {
      try {
        const brasiliaDateTime = toBrasiliaTime(dataHora);
        const data = {
          paciente_id: paciente?.id || null,
          usuario_id: user.id,
          nome_temporario: nome_temporario,
          telefone: removeMask(telefone),
          data_hora: brasiliaDateTime,
          status: status,
        };

        let res;
        if (event?.id !== undefined) {
          data.id = event.id;
          res = await updAgendamento(data);
        } else {
          res = await addAgendamento(data);
        }

        if (res.status === 201) {
          showNotification(res.data.message, "warning");
        } else {
          dispatch(agendamentosRequest());
          handleClose();
        }
      } catch (error) {
        showNotification(error.response?.data?.error, "error");
      }
    } else {
      showNotification("Paciente e Data são campos obrigatórios!", "error");
    }
  }

  const handleClose = () => {
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Agendamento Formulário"
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          marginRight: "-25vw",
          width: "80vw",
          maxWidth: "600px",
        },
      }}
    >
      <Title>Agendamento</Title>
      <Divider sx={{ mb: 2.5 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="paciente"
            fullWidth
            required
            options={pacientes}
            getOptionLabel={(pacientes) =>
              `${pacientes.nome} ${pacientes.sobrenome}`
            }
            value={paciente}
            onChange={handlePacienteChange}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField {...params} label="Paciente" variant="standard" />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="nome_temporario"
            name="nome_temporario"
            label="Nome Temporário"
            variant="standard"
            fullWidth
            value={nome_temporario}
            onChange={(e) => setNomeTemporario(e.target.value)}
            disabled={!!paciente}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputMask
            mask="(99) 99999-9999"
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
          >
            {() => (
              <TextField
                id="telefone"
                name="telefone"
                label="Telefone"
                variant="standard"
                fullWidth
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="data_hora"
            name="data_hora"
            label="Data e Hora"
            type="datetime-local"
            variant="standard"
            fullWidth
            required
            value={dataHora}
            onChange={(e) => setDataHora(e.target.value)}
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{ width: 95 }}
            color="error"
          >
            Cancelar
          </Button>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            size="small"
            sx={{ width: 95 }}
            color="success"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </Modal>
  );
};
