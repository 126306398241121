export const color = {
  primary_pure: "#1283b8",
  primary_light: "#63aeda",
  primary_dark: "#0e6b97",

  secondary_pure: "#11192a",
  secondary_light: "#ffffff0f",
  secondary_dark: "#121212",

  highlight_pure: "#107325 ",
  highlight_light: "#4a9b5a",
  highlight_dark: "#0b521b",

  neutral_white: "#FFFFFF",
  neutral_light: "#f7f7f7",
  neutral_darkest: "#454954",
  neutral_lightest: "#ffffffb3",
  neutral_dark: "#8F94A3",
  neutral_black: "#000000",

  feedback_positive: "#edf7ed",
  feedback_negative: "#fdeded",
  feedback_disabled: "#F1F3F6",
  feedback_alert: "#fff4e5",
  feedback_info: "#e5f6fd",
};
