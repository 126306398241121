import { createSlice } from "@reduxjs/toolkit";
import { pacientesRequest } from "../../actions/sistema/pacientesActions";

const pacientesSlice = createSlice({
  name: "pacientes",
  initialState: {
    pacientes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pacientesRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(pacientesRequest.fulfilled, (state, action) => {
        state.pacientes = action.payload;
        state.loading = false;
      })
      .addCase(pacientesRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default pacientesSlice.reducer;
