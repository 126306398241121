import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsuario, getUsuarios } from "../../../services/api";

export const usuarioRequest = createAsyncThunk(
  "usuario/fetchById",
  async (id, thunkAPI) => {
    try {
      const response = await getUsuario(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const usuariosRequest = createAsyncThunk(
  "usuarios/fetch",
  async (_, thunkAPI) => {
    try {
      const response = await getUsuarios();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
