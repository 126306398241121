import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import dayjs from "dayjs";

import { DynamicHeader } from "../../DynamicHeader";
import { FormContainer } from "../../../common/components/FormContainer";
import { Notification } from "../../../common/components/Notification";
import { Grid, Autocomplete, TextField, Button } from "@mui/material";

import { pacientesRequest } from "../../../redux/actions/sistema/pacientesActions";
import { addPaciente, updPaciente } from "../../../services/api";
import { tiposGenero } from "../../../functions/static_data";

export const FormPaciente = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useLocation();

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [dataNascimento, setDataNascimento] = useState(null);
  const [cpf, setCpf] = useState("");
  const [telefone, setTelefone] = useState("");
  const [genero, setGenero] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [profissao, setProfissao] = useState("");

  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const { state: formValues } = state;

  useEffect(() => {
    if (formValues?.id !== undefined) {
      setNome(formValues.nome);
      setSobrenome(formValues.sobrenome);
      setDataNascimento(formValues.data_nascimento);
      setCpf(formValues.cpf);
      setTelefone(formValues.telefone);
      setGenero(formValues.genero);
      setEndereco(formValues.endereco);
      setCidade(formValues.cidade);
      setUf(formValues.uf);
      setProfissao(formValues.profissao);
    }
  }, [formValues.id]);

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  const removeMask = (value) => {
    return value.replace(/\D/g, "");
  };

  async function handleSubmit() {
    const camposObrigatorios = {
      nome: "Nome",
      sobrenome: "Sobrenome",
      dataNascimento: "Data de Nascimento",
      telefone: "Telefone",
    };

    const erros = [];

    if (nome === "") erros.push(camposObrigatorios.nome);
    if (sobrenome === "") erros.push(camposObrigatorios.sobrenome);
    if (dataNascimento === "") erros.push(camposObrigatorios.dataNascimento);
    if (telefone === "") erros.push(camposObrigatorios.telefone);

    if (erros.length === 0) {
      try {
        const data = {
          id: formValues?.id,
          nome,
          sobrenome,
          data_nascimento: dataNascimento,
          genero,
          cpf: removeMask(cpf),
          telefone: removeMask(telefone),
          endereco,
          cidade,
          uf,
          profissao,
        };

        let res;
        if (formValues?.id !== undefined) {
          res = await updPaciente(data);
        } else {
          res = await addPaciente(data);
        }

        if (res.status === 201) {
          showNotification(res.data.message, "warning");
        } else {
          dispatch(pacientesRequest());
          handleClose();
        }
      } catch (error) {
        showNotification(error.response?.data?.error, "error");
      }
    } else {
      showNotification(
        `Os seguintes campos são obrigatórios: ${erros.join(", ")}`,
        "error"
      );
    }
  }

  const handleClose = () => {
    navigate("/pacientes");
  };

  return (
    <>
      <DynamicHeader title={"Cadastro de Paciente"} visibility={false} />
      <FormContainer>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="nome"
                  name="nome"
                  label="Nome"
                  autoComplete="nome"
                  variant="standard"
                  fullWidth
                  required
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="sobrenome"
                  name="sobrenome"
                  label="Sobrenome"
                  autoComplete="sobrenome"
                  variant="standard"
                  fullWidth
                  required
                  value={sobrenome}
                  onChange={(e) => setSobrenome(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="dataNascimento"
                  name="dataNascimento"
                  label="Data de Nascimento"
                  variant="standard"
                  type="date"
                  fullWidth
                  required
                  value={dataNascimento}
                  onChange={(e) => setDataNascimento(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputMask
                  mask="999.999.999-99"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                >
                  {() => (
                    <TextField
                      id="cpf"
                      name="cpf"
                      label="CPF"
                      variant="standard"
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputMask
                  mask="(99) 99999-9999"
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {() => (
                    <TextField
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      variant="standard"
                      fullWidth
                      required
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  id="genero"
                  fullWidth
                  options={tiposGenero()}
                  getOptionLabel={(option) => option.nome}
                  defaultValue={
                    tiposGenero().find(
                      (tipoGenero) => tipoGenero.id === formValues?.genero
                    ) || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    value === null || option.nome === value.nome
                  }
                  onChange={(e, value) => setGenero(value.id)}
                  renderInput={(params) => (
                    <TextField {...params} label="Gênero" variant="standard" />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  variant="standard"
                  fullWidth
                  value={endereco}
                  onChange={(e) => setEndereco(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  variant="standard"
                  fullWidth
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="uf"
                  name="uf"
                  label="UF"
                  variant="standard"
                  fullWidth
                  value={uf}
                  onChange={(e) => setUf(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="profissao"
                  name="profissao"
                  label="Profissão"
                  variant="standard"
                  fullWidth
                  value={profissao}
                  onChange={(e) => setProfissao(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="small"
                  sx={{ width: 95 }}
                  color="error"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  size="small"
                  sx={{ width: 95 }}
                  color="success"
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>

      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </>
  );
};

export default FormPaciente;
