import React, { useState, useCallback } from "react";
import { Modal, Box, IconButton, Slider, Button } from "@mui/material";
import Cropper from "react-easy-crop";
import CloseIcon from "@mui/icons-material/Close";
import getCroppedImg from "../../../functions/cropImage";

export const ImageEditModal = ({ open, onClose, photoPreview, onSave }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    try {
      const croppedImage = await getCroppedImg(photoPreview, croppedAreaPixels);
      onSave(croppedImage);
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 1, right: 1 }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ position: "relative", width: "100%", height: 300 }}>
          <Cropper
            image={photoPreview}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Box>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(zoom)}
          sx={{ mt: 2 }}
        />
        <Button variant="contained" onClick={handleCropSave} sx={{ mt: 2 }}>
          Salvar Recorte
        </Button>
      </Box>
    </Modal>
  );
};
