import React, { useState, useEffect } from "react";
import { HeaderContainer, Divider } from "./StyledComponents";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Title } from "../../common/components/Title";
import { decryptUser } from "../../services/auth";

export const DynamicHeader = ({
  onClickHandler,
  buttonText,
  title,
  visibility,
}) => {
  const user = decryptUser();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 430);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <HeaderContainer>
        <Title>{title}</Title>
        {visibility === true && user && user.nivelAcesso <= 2 && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClickHandler}
          >
            {isMobile ? "ADD" : buttonText}
          </Button>
        )}
      </HeaderContainer>
      <Divider sx={{ mb: 2.5 }} />
    </>
  );
};
