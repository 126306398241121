import React, { useEffect, useState } from "react";
import { Container } from "./StyledComponents";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

import { Pagination } from "@mui/material";

import { Loading } from "../Loading";

export const List = ({ rows, columns, initialState }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Array.isArray(rows) && rows.length > 0) {
      setIsLoading(false);
    }
  }, [rows]);

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        showFirstButton
        showLastButton
      />
    );
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          justifyContent: "space-between",
          display: "flex",
          marginRight: "20px",
        }}
      >
        <GridToolbar />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          rowHeight={40}
          pageSize={20}
          initialState={initialState}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          Pagination
          autoHeight
          sx={{ backgroundColor: "#fff" }}
        />
      )}
    </Container>
  );
};

/*PODE ESCOLHER ENTRE MOSTRAR TODAS AS OPÇÕES OU SOMENTE O BOTÃO DE EXPORTAR

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
components={{Toolbar: GridToolbar}}
  
------------------------------------------------------------------------------

import { DataGrid, GridToolbarContainer,  GridToolbarExport } from "@mui/x-data-grid";

components={{Toolbar: () =>{
  return <GridToolbarContainer sx={{float: 'right'}}><GridToolbarExport /></GridToolbarContainer>
}}}

*/
