import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  usuarioRequest,
  usuariosRequest,
} from "../../../redux/actions/sistema/usuariosActions";
import { dltUsuario } from "../../../services/api";
import { getTiposAcesso } from "../../../functions/static_data";

import { List } from "../../../common/components/List";
import { Notification } from "../../../common/components/Notification";
import { CustomModal } from "../../CustomModal";

import { Tooltip, IconButton, Box, Chip, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const ListaUsuarios = ({ usuarios }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState("");
  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const isMobile = useMediaQuery("(max-width:600px)");

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  const goToActions = (cellValues, action) => {
    if (action === "edit") {
      dispatch(usuarioRequest(cellValues.row.id));
      navigate("/usuarios/editar", {
        state: {
          id: cellValues?.row.id,
          nome: cellValues?.row.nome,
          sobrenome: cellValues?.row.sobrenome,
          email: cellValues?.row.email,
          nivelAcesso: cellValues?.row.nivelAcesso,
          nomeNivelAcesso: cellValues?.row.nivelAcesso,
          foto: cellValues?.row.foto,
        },
      });
    } else {
      setIsModalVisible(true);
      setId(cellValues.row.id);
    }
  };

  const handlerModalCancelClick = () => {
    setIsModalVisible(false);
    handleClose();
  };

  async function handlerModalDeleteClick() {
    try {
      const res = await dltUsuario(id);
      if (res.status === 200) {
        showNotification(res.data.message, "success");
        dispatch(usuariosRequest());
      }
    } catch (error) {
      showNotification(error.response?.data?.error, "error");
    }
    handleClose();
  }

  const handleClose = () => {
    setIsModalVisible(false);
    navigate("/usuarios");
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: "nome",
        headerName: "Nome",
        headerAlign: "center",
        flex: 1,
      },
      {
        field: "acao",
        headerName: "Ação",
        headerAlign: "center",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box sx={{ marginRight: "auto", marginLeft: "auto" }}>
              <Tooltip title="Editar">
                <IconButton
                  size="small"
                  sx={{ margin: 1, color: "#BDBDBD" }}
                  onClick={() => goToActions(cellValues, "edit")}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Deletar">
                <IconButton
                  size="small"
                  sx={{ margin: 1, color: "#BDBDBD" }}
                  onClick={() => goToActions(cellValues, "delete")}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];

    if (!isMobile) {
      baseColumns.splice(
        1,
        0,
        {
          field: "sobrenome",
          headerName: "Sobrenome",
          headerAlign: "center",
          flex: 1,
        },
        { field: "email", headerName: "Email", headerAlign: "center", flex: 1 },
        {
          field: "nomeNivelAcesso",
          headerName: "Acesso",
          headerAlign: "center",
          flex: 1,
          renderCell: (params) => {
            let color = "";
            switch (params.value) {
              case "Administrador":
                color = "info";
                break;
              case "Supervisor":
                color = "warning";
                break;
              case "Usuário":
                color = "success";
                break;
              default:
                color = "default";
            }
            return (
              <Chip label={params.value} color={color} variant="outlined" />
            );
          },
        }
      );
    }

    return baseColumns;
  }, [isMobile]);

  const rows = usuarios.map((item) => ({
    id: item.id,
    nome: item.nome,
    sobrenome: item.sobrenome,
    email: item.email,
    nivelAcesso: item.nivelAcesso,
    nomeNivelAcesso: getTiposAcesso(item.nivelAcesso),
    foto: item.foto,
  }));

  return (
    <>
      {isModalVisible && (
        <CustomModal
          clickCancel={handlerModalCancelClick}
          clickConfirm={handlerModalDeleteClick}
          title={"Confirmar exclusão?"}
        />
      )}

      <List rows={rows} columns={columns} />

      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </>
  );
};
