import styled, { createGlobalStyle } from "styled-components";
import { color } from "../../../../styles/theme/colors/colors";

const GlobalStyle = createGlobalStyle`
  .rbc-allday-cell {
    display: none !important;
  }
  .rbc-time-content {
    overflow-y: auto !important;
  }

  .rbc-toolbar button {
    background-color: ${color.primary_pure};
    color: ${color.neutral_white};
    border: 2px solid ${color.neutral_white};
    font-family: 'Montserrat', sans-serif;
  }

  .rbc-toolbar button:hover {
    background-color: ${color.primary_dark};
    color: ${color.neutral_white};
    border: 2px solid ${color.neutral_white};
  }

  .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    background-color: ${color.primary_light};
    color: ${color.neutral_white};
    border: 2px solid ${color.neutral_white};
  }

  .rbc-toolbar button.rbc-active {
    background-color: ${color.primary_light};
    border: 2px solid ${color.neutral_white};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .rbc-toolbar-label {
    color: ${color.primary_pure};
  }

`;

export const Container = styled.div`
  margin-left: 90px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  border-top: 3px solid ${color.primary_pure};
  height: calc(100vh - 205px);
  ${GlobalStyle}
`;

export const CalendarContainer = styled.div`
  background-color: ${color.neutral_white};
  height: 100%;
  overflow-y: auto;
`;
