import React from "react";
import { ModalBackground, Modal } from "./StyledComponents";
import { Grid, Typography, Button } from "@mui/material";

export const CustomModal = (props) => {
  return (
    <ModalBackground>
      <Modal>
        <Typography variant="h6" gutterBottom>
          {props.title}
        </Typography>
        <Grid>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={props.clickCancel}
            sx={{ width: 100, mr: "5px" }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            color="success"
            onClick={props.clickConfirm}
            sx={{ width: 100, ml: "5px" }}
          >
            Confirmar
          </Button>
        </Grid>
      </Modal>
    </ModalBackground>
  );
};
