import { createSlice } from "@reduxjs/toolkit";
import { consultasRequest } from "../../actions/sistema/consultasActions";

const consultasSlice = createSlice({
  name: "consultas",
  initialState: {
    consultas: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(consultasRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(consultasRequest.fulfilled, (state, action) => {
        state.consultas = action.payload;
        state.loading = false;
      })
      .addCase(consultasRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default consultasSlice.reducer;
