import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getConsulta,
  getConsultas,
  getConsultasPaciente,
} from "../../../services/api";

export const consultaRequest = createAsyncThunk(
  "consulta/fetchById",
  async (id, thunkAPI) => {
    try {
      const response = await getConsulta(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const consultasRequest = createAsyncThunk(
  "consultas/fetch",
  async (_, thunkAPI) => {
    try {
      const response = await getConsultas();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const consultasPacienteRequest = createAsyncThunk(
  "consultas/fetchByPaciente",
  async (idPaciente, thunkAPI) => {
    try {
      const response = await getConsultasPaciente(idPaciente);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
