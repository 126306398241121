import { createSlice } from "@reduxjs/toolkit";
import { pacienteRequest } from "../../actions/sistema/pacientesActions";

const pacienteSlice = createSlice({
  name: "paciente",
  initialState: {
    paciente: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pacienteRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(pacienteRequest.fulfilled, (state, action) => {
        state.paciente = action.payload;
        state.loading = false;
      })
      .addCase(pacienteRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default pacienteSlice.reducer;
