import React, { useState, useEffect } from "react";
import { color } from "../../styles/theme/colors/colors";
import { Avatar } from "@mui/material";
import {
  HeaderContainer,
  LogoContainer,
  UserContainer,
  HeaderName,
} from "./StyledComponents";
import logo from "../../assets/logo2.png";
import { decryptUser } from "../../services/auth";

export const Header = () => {
  const user = decryptUser();
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (user?.foto) {
      const filePath = require(`../../assets/uploads/users/${user.foto
        .split("/")
        .pop()}`);

      setAvatarUrl(filePath.default || filePath);
    }
  }, [user, avatarUrl]);

  return (
    <HeaderContainer>
      <LogoContainer>
        <img src={logo} alt="Logo" />
      </LogoContainer>
      <UserContainer>
        <HeaderName>Olá, {user.nome}</HeaderName>
        <Avatar
          src={avatarUrl}
          sx={{ ml: "10px", bgcolor: `${color.primary_pure}` }}
        />
      </UserContainer>
    </HeaderContainer>
  );
};
