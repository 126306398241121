import { setStorage } from "./storage";
import { verificaLogin } from "./api";

import CryptoJS from "crypto-js";

export const logout = () => {
  localStorage.clear();
};

export async function signIn(data) {
  try {
    const res = await verificaLogin(data);
    if (res.status === 200 && res.data) {
      const { user, token, tokenExpiration } = res.data;
      const userString = JSON.stringify(user);
      const userEncrypted = CryptoJS.AES.encrypt(userString, token).toString();

      setStorage("user", userEncrypted);
      setStorage("token", token);
      setStorage("tokenExpiration", tokenExpiration);
      return true;
    }
  } catch (error) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.error) {
      return { error: error.response.data.error };
    }
    return { error: "Erro desconhecido ao tentar realizar login" };
  }
}

export const decryptUser = () => {
  const userEncrypted = localStorage?.getItem("user")?.slice(1, -1);
  const token = localStorage?.getItem("token")?.slice(1, -1);

  if (userEncrypted && token) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(userEncrypted, token);
      const userString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(userString);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
};
