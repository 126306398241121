import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DynamicHeader } from "../../components/DynamicHeader";
import { ListaConsultas } from "../../components/Consulta/ListaConsultas";

import { consultasRequest } from "../../redux/actions/sistema/consultasActions";

export const Consultas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToFormPage = () => {
    navigate("/consultas/adicionar", { state: {} });
  };

  const consultas = useSelector((state) => state.consultasReducer?.consultas);

  useEffect(() => {
    try {
      dispatch(consultasRequest());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <>
      <DynamicHeader
        onClickHandler={goToFormPage}
        buttonText={"Adicionar"}
        title={"Lista de Consultas"}
        visibility={true}
      />
      <ListaConsultas consultas={consultas} />
    </>
  );
};
