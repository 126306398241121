import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DynamicHeader } from "../../components/DynamicHeader";
import { ListaUsuarios } from "../../components/Usuario/ListaUsuarios";

import { usuariosRequest } from "../../redux/actions/sistema/usuariosActions";

export const Usuarios = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToFormPage = () => {
    navigate("/usuarios/adicionar", { state: {} });
  };

  const usuarios = useSelector((state) => state.usuariosReducer?.usuarios);

  useEffect(() => {
    try {
      dispatch(usuariosRequest());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <>
      <DynamicHeader
        onClickHandler={goToFormPage}
        buttonText={"Adicionar"}
        title={"Lista de Usuários"}
        visibility={true}
      />
      <ListaUsuarios usuarios={usuarios} />
    </>
  );
};
