import { createSlice } from "@reduxjs/toolkit";
import { usuarioRequest } from "../../actions/sistema/usuariosActions";

const usuarioSlice = createSlice({
  name: "usuario",
  initialState: {
    usuario: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(usuarioRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(usuarioRequest.fulfilled, (state, action) => {
        state.usuario = action.payload;
        state.loading = false;
      })
      .addCase(usuarioRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default usuarioSlice.reducer;
