import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

import {
  pacienteRequest,
  pacientesRequest,
} from "../../../redux/actions/sistema/pacientesActions";

import { consultasPacienteRequest } from "../../../redux/actions/sistema/consultasActions";

import { dltPaciente } from "../../../services/api";

import { List } from "../../../common/components/List";
import { Notification } from "../../../common/components/Notification";
import { CustomModal } from "../../CustomModal";
import { ModalConsultas } from "../../Consulta/ModalConsultas";

import { Tooltip, IconButton, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getTiposGenero } from "../../../functions/static_data";
import { formatCpf, formatTelefone } from "../../../functions/formatters";

export const ListaPacientes = ({ pacientes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConsultasModalVisible, setIsConsultasModalVisible] = useState(false);
  const [id, setId] = useState("");
  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const isMobile = useMediaQuery("(max-width:600px)");

  const consultasPaciente = useSelector(
    (state) => state.consultasPacienteReducer.consultasPaciente
  );

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  const goToActions = (cellValues, action) => {
    if (action === "edit") {
      dispatch(pacienteRequest(cellValues.row.id));
      navigate("/pacientes/editar", {
        state: {
          id: cellValues?.row.id,
          nome: cellValues?.row.nome,
          sobrenome: cellValues?.row.sobrenome,
          data_nascimento: cellValues?.row.data_nascimento,
          genero: cellValues?.row.genero || null,
          cpf: cellValues?.row.cpf || "",
          telefone: cellValues?.row.telefone,
          endereco: cellValues?.row.endereco || "",
          cidade: cellValues?.row.cidade || "",
          uf: cellValues?.row.uf || "",
          profissao: cellValues?.row.profissao || "",
        },
      });
    } else if (action === "delete") {
      setIsModalVisible(true);
      setId(cellValues.row.id);
    } else if (action === "view") {
      dispatch(consultasPacienteRequest(cellValues?.row.id));
      setIsConsultasModalVisible(true);
    }
  };

  const handlerModalCancelClick = () => {
    setIsModalVisible(false);
    handleClose();
  };

  async function handlerModalDeleteClick() {
    try {
      const res = await dltPaciente(id);
      if (res.status === 200) {
        showNotification(res.data.message, "success");
        dispatch(pacientesRequest());
      }
    } catch (error) {
      showNotification(error.response?.data?.error, "error");
    }
    handleClose();
  }

  const handleClose = () => {
    setIsModalVisible(false);
    navigate("/pacientes");
  };

  const columns = isMobile
    ? [
        { field: "nome", headerName: "Nome", headerAlign: "center", flex: 1 },
        {
          field: "sobrenome",
          headerName: "Sobrenome",
          headerAlign: "center",
          flex: 1,
        },
        {
          field: "acao",
          headerName: "Ação",
          headerAlign: "center",
          flex: 1,
          renderCell: (cellValues) => {
            return (
              <Box sx={{ marginRight: "auto", marginLeft: "auto" }}>
                <Tooltip title="Visualizar Consultas">
                  <IconButton
                    size="small"
                    sx={{ margin: 1, color: "#BDBDBD" }}
                    onClick={() => goToActions(cellValues, "view")}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    size="small"
                    sx={{ margin: 1, color: "#BDBDBD" }}
                    onClick={(event) => {
                      goToActions(cellValues, "edit");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton
                    size="small"
                    sx={{ margin: 1, color: "#BDBDBD" }}
                    onClick={(event) => {
                      goToActions(cellValues, "delete");
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        },
      ]
    : [
        { field: "nome", headerName: "Nome", headerAlign: "center", flex: 1 },
        {
          field: "sobrenome",
          headerName: "Sobrenome",
          headerAlign: "center",
          flex: 1,
        },
        {
          field: "data_nascimento_formatada",
          headerName: "Data de Nascimento",
          headerAlign: "center",
          flex: 1,
          hide: true,
        },
        {
          field: "genero",
          headerName: "ID Gênero",
          headerAlign: "center",
          flex: 1,
          hide: true,
        },
        {
          field: "nomeGenero",
          headerName: "Gênero",
          headerAlign: "center",
          flex: 1,
          hide: true,
        },
        { field: "cpf", headerName: "CPF", headerAlign: "center", flex: 1 },
        {
          field: "telefone",
          headerName: "Telefone",
          headerAlign: "center",
          flex: 1,
        },
        {
          field: "endereco",
          headerName: "Endereço",
          headerAlign: "center",
          flex: 1,
          hide: true,
        },
        {
          field: "cidade",
          headerName: "Cidade",
          headerAlign: "center",
          flex: 1,
        },
        {
          field: "uf",
          headerName: "UF",
          headerAlign: "center",
          flex: 1,
          hide: true,
        },
        {
          field: "profissao",
          headerName: "Profissão",
          headerAlign: "center",
          flex: 1,
          hide: true,
        },
        {
          field: "acao",
          headerName: "Ação",
          headerAlign: "center",
          flex: 1,
          renderCell: (cellValues) => {
            return (
              <Box sx={{ marginRight: "auto", marginLeft: "auto" }}>
                <Tooltip title="Visualizar Consultas">
                  <IconButton
                    size="small"
                    sx={{ margin: 1, color: "#BDBDBD" }}
                    onClick={() => goToActions(cellValues, "view")}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    size="small"
                    sx={{ margin: 1, color: "#BDBDBD" }}
                    onClick={(event) => {
                      goToActions(cellValues, "edit");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton
                    size="small"
                    sx={{ margin: 1, color: "#BDBDBD" }}
                    onClick={(event) => {
                      goToActions(cellValues, "delete");
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        },
      ];

  const rows = pacientes.map((item) => ({
    id: item.id,
    nome: item.nome,
    sobrenome: item.sobrenome,
    data_nascimento_formatada: dayjs(item.data_nascimento).format("DD/MM/YYYY"),
    data_nascimento: item.data_nascimento,
    genero: item.genero || null,
    nomeGenero: getTiposGenero(item.genero),
    cpf: formatCpf(item.cpf),
    telefone: formatTelefone(item.telefone),
    endereco: item.endereco || "",
    cidade: item.cidade || "",
    uf: item.uf || "",
    profissao: item.profissao || "",
  }));

  return (
    <>
      {isModalVisible && (
        <CustomModal
          clickCancel={() => handlerModalCancelClick()}
          clickConfirm={() => handlerModalDeleteClick()}
          title={"Confirmar exclusão?"}
        />
      )}

      {isConsultasModalVisible && (
        <ModalConsultas
          isOpen={isConsultasModalVisible}
          onRequestClose={() => setIsConsultasModalVisible(false)}
          consultas={consultasPaciente}
        />
      )}

      <List rows={rows} columns={columns} />

      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </>
  );
};
