import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  consultaRequest,
  consultasRequest,
} from "../../../redux/actions/sistema/consultasActions";
import { dltConsulta } from "../../../services/api";

import { List } from "../../../common/components/List";
import { Notification } from "../../../common/components/Notification";
import { CustomModal } from "../../CustomModal";

import { Tooltip, IconButton, Box, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getTiposGenero } from "../../../functions/static_data";
import { formatCpf, formatTelefone } from "../../../functions/formatters";

export const ListaConsultas = ({ consultas }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState("");
  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  const goToActions = (cellValues, action) => {
    if (action === "edit") {
      dispatch(consultaRequest(cellValues.row.id));
      navigate("/consultas/editar", {
        state: {
          id: cellValues?.row.id,
          nome: cellValues?.row.nome,
          sobrenome: cellValues?.row.sobrenome,
          data_nascimento: cellValues?.row.data_nascimento,
          genero: cellValues?.row.genero,
          cpf: cellValues?.row.cpf,
          telefone: cellValues?.row.telefone,
          endereco: cellValues?.row.endereco,
          cidade: cellValues?.row.cidade,
          uf: cellValues?.row.uf,
          profissao: cellValues?.row.profissao,
        },
      });
    } else {
      setIsModalVisible(true);
      setId(cellValues.row.id);
    }
  };

  const handlerModalCancelClick = () => {
    setIsModalVisible(false);
    handleClose();
  };

  async function handlerModalDeleteClick() {
    try {
      const res = await dltConsulta(id);
      if (res.status === 200) {
        showNotification(res.data.message, "success");
        dispatch(consultasRequest());
      }
    } catch (error) {
      showNotification(error.response?.data?.error, "error");
    }
    handleClose();
  }

  const handleClose = () => {
    setIsModalVisible(false);
    navigate("/consultas");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      flex: 1,
      hide: true,
      disableExport: true,
    },
    { field: "nome", headerName: "Nome", headerAlign: "center", flex: 1 },
    {
      field: "sobrenome",
      headerName: "Sobrenome",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "data_nascimento",
      headerName: "Data de Nascimento",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    {
      field: "genero",
      headerName: "ID Gênero",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    {
      field: "nomeGenero",
      headerName: "Gênero",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    { field: "cpf", headerName: "CPF", headerAlign: "center", flex: 1 },
    {
      field: "telefone",
      headerName: "Telefone",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "endereco",
      headerName: "Endereço",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    { field: "cidade", headerName: "Cidade", headerAlign: "center", flex: 1 },
    {
      field: "uf",
      headerName: "UF",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    {
      field: "profissao",
      headerName: "Profissão",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
    {
      field: "acao",
      headerName: "Ação",
      headerAlign: "center",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <>
            <Box sx={{ marginRight: "auto", marginLeft: "auto" }}>
              <Tooltip title="Editar">
                <IconButton
                  size="small"
                  sx={{ margin: 1, color: "#BDBDBD" }}
                  onClick={(event) => {
                    goToActions(cellValues, "edit");
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Deletar">
                <IconButton
                  size="small"
                  sx={{ margin: 1, color: "#BDBDBD" }}
                  onClick={(event) => {
                    goToActions(cellValues, "delete");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  const rows = [];
  consultas.map((item) => {
    const row = {};
    row.id = item.id;
    row.nome = item.nome;
    row.sobrenome = item.sobrenome;
    row.data_nascimento = item.data_nascimento;
    row.genero = item.genero;
    row.nomeGenero = getTiposGenero(item.genero);
    row.cpf = formatCpf(item.cpf);
    row.telefone = formatTelefone(item.telefone);
    row.endereco = item.endereco;
    row.cidade = item.cidade;
    row.uf = item.uf;
    row.profissao = item.profissao;
    rows.push(row);
    return rows;
  });

  return (
    <>
      {isModalVisible && (
        <CustomModal
          clickCancel={() => handlerModalCancelClick()}
          clickConfirm={() => handlerModalDeleteClick()}
          title={"Confirmar exclusão?"}
        />
      )}

      <List rows={rows} columns={columns} />

      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </>
  );
};
