export const font = {
  family_base: "Montserrat",

  weight_bold: "700",
  weight_medium: "600",
  weight_regular: "400",

  size_xxxs: "12px",
  size_xxs: "14px",
  size_xs: "16px",
  size_sm: "20px",
  size_md: "24px",
  size_lg: "32px",
  size_xl: "40px",
  size_xxl: "48px",
  size_xxxl: "64px",
  size_display: "80px",
  size_giant: "96px",
};
