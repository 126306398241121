import React from "react";
import { DynamicHeader } from "../../components/DynamicHeader";

export const Dashboards = () => {
  return (
    <>
      <DynamicHeader
        onClickHandler={""}
        buttonText={"Adicionar"}
        title={"Dashboards"}
        visibility={false}
      />
    </>
  );
};
