import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DynamicHeader } from "../../components/DynamicHeader";
import { ListaAgendamentos } from "../../components/Agendamento/ListaAgendamentos";

import { agendamentosRequest } from "../../redux/actions/sistema/agendamentosActions";
import { decryptUser } from "../../services/auth";

export const Agendamentos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToFormPage = () => {
    navigate("/consultas/adicionar", { state: {} });
  };

  const agendamentos = useSelector(
    (state) => state.agendamentosReducer?.agendamentos
  );

  useEffect(() => {
    try {
      dispatch(agendamentosRequest());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const user = decryptUser();
  const showButton = user?.nivelAcesso === 1;

  return (
    <>
      <DynamicHeader
        onClickHandler={goToFormPage}
        buttonText={"Nova Consulta"}
        title={"Agendamentos"}
        visibility={showButton}
      />
      <ListaAgendamentos agendamentos={agendamentos} />
    </>
  );
};
