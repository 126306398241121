import { createSlice } from "@reduxjs/toolkit";
import { agendamentosRequest } from "../../actions/sistema/agendamentosActions";

const agendamentosSlice = createSlice({
  name: "agendamentos",
  initialState: {
    agendamentos: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(agendamentosRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(agendamentosRequest.fulfilled, (state, action) => {
        state.agendamentos = action.payload;
        state.loading = false;
      })
      .addCase(agendamentosRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default agendamentosSlice.reducer;
