import { color } from "../../../styles/theme/colors/colors";
import styled from "styled-components";

export const BackDrop = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  background: rgba(143, 148, 163, 0.24);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;
`;

export const SidebarContainer = styled.div`
  background-color: ${color.secondary_pure};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  position: fixed;
  border-radius: 0 16px 16px 0;
  box-shadow: 0 0 10px #afb2bb;
  overflow: hidden;
  width: ${({ open }) => (open ? "200px" : "58px")};
  transition: width 0.5s ease-out;
  z-index: 998;
  padding: 10px;
  margin-top: -70px;
`;

export const Menu = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "flex")};
  align-items: center;
  text-decoration: unset;
  padding-left: 8px;
  height: 55px;
  width: auto;
  color: ${color.neutral_lightest};
  &:last-child {
    margin-bottom: 0;
  }
  :hover {
    color: ${color.neutral_white};
    cursor: pointer;
  }
`;

export const MenuItems = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "flex")};
  align-items: center;
  text-decoration: unset;
  padding-left: 8px;
  width: 178px;
  height: 45px;
  color: ${color.neutral_lightest};
  &:last-child {
    margin-bottom: 0;
  }
  :hover {
    background-color: ${({ isOpen }) => isOpen && `${color.secondary_light}`};
    align-items: center;
    border-radius: 8px;
    color: ${color.neutral_white};
    cursor: pointer;
  }
`;

export const TextItems = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  margin-left: 16px;
`;

export const SidebarHeader = styled.div``;

export const SidebarFooter = styled.div``;
