import React, { useState, useEffect } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { ptBR } from "date-fns/locale";
import { Container, CalendarContainer } from "./StyledComponents";
import { FormAgendamento } from "../FormAgendamento";
import { EventTooltip } from "../EventTooltip";
import { useMediaQuery } from "@mui/material";
import { dltAgendamento } from "../../../services/api";
import { color } from "../../../styles/theme/colors/colors";
import "react-big-calendar/lib/css/react-big-calendar.css";

const CustomHeader = ({ date, localizer }) => {
  return (
    <div
      style={{ textAlign: "center", color: color.primary_pure, height: "60px" }}
    >
      <div style={{ fontSize: "24px", fontWeight: "bold" }}>
        {localizer.format(date, "dd")}
      </div>
      <div style={{ fontSize: "14px" }}>{localizer.format(date, "EEEE")}</div>
    </div>
  );
};

const slotPropGetter = (date) => {
  const startHour = 8;
  const endHour = 18;
  const hour = date.getHours();
  if (hour >= startHour && hour < endHour) {
    return {
      style: { backgroundColor: "#ffffff" },
    };
  } else {
    return {
      style: { backgroundColor: "#f7f7f7" },
    };
  }
};

const locales = {
  "pt-BR": ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const truncateTitle = (title, maxLength) => {
  if (title.length > maxLength) {
    return title.substring(0, maxLength) + "...";
  }
  return title;
};

export const ListaAgendamentos = ({ agendamentos }) => {
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const isMobile = useMediaQuery("(max-width:600px)");
  const [view, setView] = useState(isMobile ? "day" : "work_week");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [tooltipEvent, setTooltipEvent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({});

  useEffect(() => {
    const transformedEvents = agendamentos.map((agendamento) => {
      const title = agendamento.nome_temporario
        ? truncateTitle(agendamento.nome_temporario, 12)
        : agendamento.paciente
        ? truncateTitle(
            agendamento.paciente.nome + " " + agendamento.paciente.sobrenome,
            12
          )
        : "Sem Nome";

      return {
        id: agendamento.id,
        title: title,
        start: new Date(agendamento.data_hora),
        end: new Date(
          new Date(agendamento.data_hora).getTime() + 20 * 60 * 1000
        ),
        paciente_id: agendamento.paciente?.id,
        paciente_nome: agendamento.paciente?.nome,
        paciente_sobrenome: agendamento.paciente?.sobrenome,
        telefone: agendamento.telefone,
        nome_temporario: agendamento.nome_temporario,
        status: agendamento.status,
      };
    });
    setEvents(transformedEvents);
  }, [agendamentos]);

  useEffect(() => {
    setView(isMobile ? "day" : "work_week");
  }, [isMobile]);

  const handleNavigate = (date) => {
    setCurrentDate(date);
  };

  const customDayPropGetter = (date) => {
    if (getDay(date) === 0 || getDay(date) === 6) {
      return {
        className: "weekend",
        style: {
          backgroundColor: "#f0f0f0",
        },
      };
    }
  };

  const handleSelectSlot = ({ start }) => {
    setSelectedEvent({
      start,
      end: new Date(start.getTime() + 60 * 60 * 1000),
    });
    setIsModalOpen(true);
  };

  const handleSelectEvent = (event, e) => {
    const rect = e.target.getBoundingClientRect();
    setTooltipEvent(event);
    setTooltipPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleDoubleClickEvent = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleEditEvent = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
    setTooltipEvent(null);
  };

  const handleDeleteEvent = async (event) => {
    try {
      await dltAgendamento(event.id);
      setEvents(events.filter((e) => e.id !== event.id));
      setTooltipEvent(null);
    } catch (error) {
      console.error("Erro ao excluir agendamento:", error);
    }
  };

  const handleCloseTooltip = () => {
    setTooltipEvent(null);
  };

  const messages = {
    today: "Hoje",
    previous: "Anterior",
    next: "Próximo",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    work_week: "Semana de Trabalho",
    showMore: (total) => `+${total} mais...`,
  };

  return (
    <Container>
      <CalendarContainer>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["week", "day", "work_week"]}
          step={60}
          timeslots={1}
          view={view}
          onView={setView}
          date={currentDate}
          onNavigate={handleNavigate}
          culture="pt-BR"
          messages={messages}
          dayPropGetter={customDayPropGetter}
          slotPropGetter={slotPropGetter}
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          onDoubleClickEvent={handleDoubleClickEvent}
          min={new Date(0, 0, 0, 0, 0)}
          max={new Date(0, 0, 0, 23, 59)}
          scrollToTime={new Date(0, 0, 0, 8, 0)}
          formats={{
            weekdayFormat: (date, culture, localizer) =>
              localizer.format(date, "EEEE", culture),
          }}
          components={{
            day: { header: CustomHeader },
            week: { header: CustomHeader },
            work_week: { header: CustomHeader },
          }}
        />
        {tooltipEvent && (
          <EventTooltip
            event={tooltipEvent}
            onEdit={handleEditEvent}
            onDelete={handleDeleteEvent}
            onClose={handleCloseTooltip}
            style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
          />
        )}
      </CalendarContainer>
      <FormAgendamento
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        event={selectedEvent}
      />
    </Container>
  );
};
