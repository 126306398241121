import validator from "validator";

export function validateInputLogin(email, senha) {
  const validationErrors = [];
  if (!email || !validator.isEmail(email)) {
    validationErrors.push("E-mail inválido");
  }
  if (!senha || senha.length < 2) {
    validationErrors.push("Senha inválida");
  }
  return {
    isValid: validationErrors.length === 0,
    errors: validationErrors,
  };
}

export function validateEmail(email, senha) {
  const validationErrors = [];
  if (!email || !validator.isEmail(email)) {
    validationErrors.push("Email inválido");
  }
  return {
    isValid: validationErrors.length === 0,
    errors: validationErrors,
  };
}
