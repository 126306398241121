import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAgendamento, getAgendamentos } from "../../../services/api";

export const agendamentoRequest = createAsyncThunk(
  "agendamento/fetchById",
  async (id, thunkAPI) => {
    try {
      const response = await getAgendamento(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const agendamentosRequest = createAsyncThunk(
  "agendamentos/fetch",
  async (_, thunkAPI) => {
    try {
      const response = await getAgendamentos();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
