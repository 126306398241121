import React from "react";
import { Sidebar } from "../Sidebar";

export const LayoutStructure = ({ children }) => {
  return (
    <>
      <Sidebar>{children}</Sidebar>
    </>
  );
};
