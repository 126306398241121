import { combineReducers } from "redux";
import agendamentosReducer from "./sistema/showAgendamentosReducer";
import agendamentoReducer from "./sistema/showAgendamentoReducer";
import consultasReducer from "./sistema/showConsultasReducer";
import consultasPacienteReducer from "./sistema/showConsultasPacienteReducer";
import consultaReducer from "./sistema/showConsultaReducer";
import pacientesReducer from "./sistema/showPacientesReducer";
import pacienteReducer from "./sistema/showPacienteReducer";
import usuariosReducer from "./sistema/showUsuariosReducer";
import usuarioReducer from "./sistema/showUsuarioReducer";

export default combineReducers({
  agendamentosReducer,
  agendamentoReducer,
  consultasReducer,
  consultasPacienteReducer,
  consultaReducer,
  pacientesReducer,
  pacienteReducer,
  usuariosReducer,
  usuarioReducer,
});
