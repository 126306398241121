import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  BackgroudImage,
  ContainerForm,
  BoxForm,
  Logo,
  BoxFooter,
} from "./StyledComponents";

import {
  Grid,
  TextField,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Notification } from "../../common/components/Notification";
import { Footer } from "../Footer";

import { signIn } from "../../services/auth";
import { validateInputLogin } from "../../functions/validator";

export const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  async function handleSubmit() {
    const { isValid, errors } = validateInputLogin(email, senha);

    if (!isValid) {
      showNotification(errors.join("\n e \n"), "error");
      return;
    }

    try {
      const data = { email, senha };
      const loginSuccess = await signIn(data);
      if (loginSuccess === true) {
        navigate("/");
      } else {
        showNotification(loginSuccess.error, "error");
      }
    } catch (err) {
      showNotification(
        "Erro ao tentar realizar login. Tente novamente mais tarde.",
        "error"
      );
    }
  }

  return (
    <>
      <Grid
        container
        component="main"
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item xs={false} sm={6} md={8}>
          <BackgroudImage />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ContainerForm>
            <Logo />
            <BoxForm>
              <TextField
                id="user"
                name="user"
                label="E-mail"
                type="email"
                variant="outlined"
                margin="normal"
                autoComplete="email"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <FormControl
                variant="outlined"
                sx={{ width: "100%", marginBottom: 2 }}
              >
                <InputLabel htmlFor="outlined-adornment-password" required>
                  Senha
                </InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  label="Senha"
                  type={showPassword ? "text" : "password"}
                  required
                  fullWidth
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2, borderRadius: 1 }}
                fullWidth
              >
                Entrar
              </Button>
            </BoxForm>

            <BoxFooter>
              <Footer />
            </BoxFooter>
          </ContainerForm>
        </Grid>
      </Grid>

      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </>
  );
};
