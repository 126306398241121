import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DynamicHeader } from "../../DynamicHeader";
import { FormContainer } from "../../../common/components/FormContainer";
import { Notification } from "../../../common/components/Notification";
import { ImageEditModal } from "../../../common/components/ImageEditModal";
import {
  Grid,
  Autocomplete,
  TextField,
  Box,
  Button,
  Avatar,
  IconButton,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";

import { usuariosRequest } from "../../../redux/actions/sistema/usuariosActions";
import { addUsuario, updUsuario } from "../../../services/api";
import { validateEmail } from "../../../functions/validator";
import { tiposAcesso } from "../../../functions/static_data";

export const FormUsuario = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useLocation();

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nivelAcesso, setNivelAcesso] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [photoName, setPhotoName] = useState(null);
  const [removePhoto, setRemovePhoto] = useState(false);

  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const { state: formValues } = state;

  useEffect(() => {
    if (formValues?.id !== undefined) {
      setNome(formValues.nome);
      setSobrenome(formValues.sobrenome);
      setEmail(formValues.email);
      setNivelAcesso(formValues.nivelAcesso);
      if (formValues.foto) {
        const url = require(`../../../assets/uploads/users/${formValues.foto
          .split("/")
          .pop()}`);
        setPhotoPreview(url.default || url);
        setPhotoName(formValues.foto.split("/").pop());
      }
    }
  }, [formValues.id, dispatch]);

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setPhoto(file);
    setPhotoPreview(URL.createObjectURL(file));
    setIsEditModalOpen(true);
    setPhotoName(file.name);
    setRemovePhoto(false);
    event.target.value = null;
  };

  const handleRemovePhoto = () => {
    setPhoto(null);
    setPhotoPreview(null);
    setRemovePhoto(true);
    formValues.foto = null;
  };

  const handleSaveCroppedImage = (croppedImage) => {
    setPhoto(croppedImage);
    setPhotoPreview(URL.createObjectURL(croppedImage));
    setIsHovered(false);
    setRemovePhoto(false);
  };

  async function handleSubmit() {
    if (nome !== "" && sobrenome !== "") {
      const { isValid, errors } = validateEmail(email, senha);

      if (!isValid) {
        showNotification(errors.join("\n e \n"), "error");
        return;
      }

      try {
        const data = new FormData();
        data.append("id", formValues?.id);
        data.append("nome", nome);
        data.append("sobrenome", sobrenome);
        data.append("email", email);
        data.append("senha", senha);
        data.append("nivelAcesso", nivelAcesso);
        if (photo) {
          data.append("foto", photo, photoName);
        }
        if (removePhoto) {
          data.append("removeFoto", "true");
        }

        let res;
        if (formValues?.id !== undefined) {
          res = await updUsuario(data);
        } else {
          res = await addUsuario(data);
        }

        if (res.status === 201) {
          showNotification(res.data.message, "warning");
        } else {
          dispatch(usuariosRequest());
          handleClose();
        }
      } catch (error) {
        showNotification(error.response?.data?.error, "error");
      }
    } else {
      showNotification("Todos os campos são obrigatórios!", "error");
    }
  }

  const handleClose = () => {
    navigate("/usuarios");
  };

  return (
    <>
      <DynamicHeader title={"Cadastro de Usuário"} visibility={false} />
      <FormContainer>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="nome"
                  name="nome"
                  label="Nome"
                  autoComplete="nome"
                  variant="standard"
                  fullWidth
                  required
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="sobrenome"
                  name="sobrenome"
                  label="Sobrenome"
                  autoComplete="sobrenome"
                  variant="standard"
                  fullWidth
                  required
                  value={sobrenome}
                  onChange={(e) => setSobrenome(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  autoComplete="email"
                  type="email"
                  variant="standard"
                  required
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="senha"
                  name="senha"
                  label="Senha"
                  type="password"
                  variant="standard"
                  required
                  fullWidth
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="nivelAcesso"
                  fullWidth
                  required
                  options={tiposAcesso()}
                  getOptionLabel={(option) => option.nome}
                  defaultValue={
                    tiposAcesso().find(
                      (tipoAcess) => tipoAcess.id === formValues?.nivelAcesso
                    ) || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    value === null || option.nome === value.nome
                  }
                  onChange={(e, value) => setNivelAcesso(value.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nível de Acesso"
                      variant="standard"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  sx={{ position: "relative", display: "inline-block" }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    onChange={handlePhotoChange}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      aria-label="upload picture"
                      component="span"
                      sx={{ color: "grey.500" }}
                    >
                      {photoPreview ? (
                        <Avatar
                          sx={{
                            width: 128,
                            height: 128,
                            bgcolor: "grey.300",
                          }}
                          src={photoPreview}
                        />
                      ) : (
                        <PhotoCamera sx={{ fontSize: 96 }} />
                      )}
                    </IconButton>
                  </label>
                  {isHovered && photoPreview && (
                    <IconButton
                      onClick={handleRemovePhoto}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="small"
                  sx={{ width: 95 }}
                  color="error"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  size="small"
                  sx={{ width: 95 }}
                  color="success"
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>

      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
      <ImageEditModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        photoPreview={photoPreview}
        onSave={handleSaveCroppedImage}
      />
    </>
  );
};
