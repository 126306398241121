import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DynamicHeader } from "../../components/DynamicHeader";
import { ListaPacientes } from "../../components/Paciente/ListaPacientes";

import { pacientesRequest } from "../../redux/actions/sistema/pacientesActions";

export const Pacientes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToFormPage = () => {
    navigate("/pacientes/adicionar", { state: {} });
  };

  const pacientes = useSelector((state) => state.pacientesReducer?.pacientes);

  useEffect(() => {
    try {
      dispatch(pacientesRequest());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <>
      <DynamicHeader
        onClickHandler={goToFormPage}
        buttonText={"Adicionar"}
        title={"Lista de Pacientes"}
        visibility={true}
      />
      <ListaPacientes pacientes={pacientes} />
    </>
  );
};
