import styled from "styled-components";

export const Divider = styled.div`
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
`;
