import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPaciente, getPacientes } from "../../../services/api";

export const pacienteRequest = createAsyncThunk(
  "paciente/fetchById",
  async (id, thunkAPI) => {
    try {
      const response = await getPaciente(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const pacientesRequest = createAsyncThunk(
  "pacientes/fetch",
  async (_, thunkAPI) => {
    try {
      const response = await getPacientes();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
