import React from "react";
import Typography from "@mui/material/Typography";

export const Footer = () => {
  return (
    <div>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        sx={{ fontSize: "0.670rem" }}
      >
        {"Copyright © "}
        {new Date().getFullYear()}
        {", "}
        IG Develpment. All rights reserved.
      </Typography>
    </div>
  );
};
