import styled from "styled-components";
import { color } from "../../../styles/theme/colors/colors";
import { font } from "../../../styles/theme/font/font";

export const HeaderContainer = styled.div`
  margin-left: 58px;
  padding-left: 30px;
  padding-right: 30px;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: ${color.neutral_white};
`;

export const Divider = styled.div`
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-bottom: 20px;
  margin-left: 58px;
  margin-right: 0px;
`;
