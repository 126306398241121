import { createSlice } from "@reduxjs/toolkit";
import { usuariosRequest } from "../../actions/sistema/usuariosActions";

const usuariosSlice = createSlice({
  name: "usuarios",
  initialState: {
    usuarios: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(usuariosRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(usuariosRequest.fulfilled, (state, action) => {
        state.usuarios = action.payload;
        state.loading = false;
      })
      .addCase(usuariosRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default usuariosSlice.reducer;
